.p-tabview-nav {
    border-bottom: 1px solid rgb(228, 230, 239);
    margin-bottom: 1rem !important;

    >li[role="presentation"] {
        border-radius: 5px 5px 0 0;
        border: 1px solid rgb(228, 230, 239);
        border-bottom-color: transparent;
        padding: 1rem 1.4rem;

        &:first-child {
            border-radius: 5px 0 0 0;
        }

        &:not(:first-child):not(:nth-last-child(2)) {
            border-radius: 0;
        }

        &:nth-last-child(2) {
            border-top-left-radius: 0;
        }

        &:not(:first-child) {
            border-left-width: 0;
        }

        &.p-highlight a {
            color: unset;
        }
    }
}

.tab-body {
    padding-top: 1%;
    padding-bottom: 1%;
}
